$(document).ready(function() {

    $('.afs-insights-teaser .owl-carousel').owlCarousel({
        loop:false,
        stagePadding: 0,
        autoWidth:false,
        margin: 30,
        nav:false,
        responsive:{
            0:{
                items:1
            },
            780:{
                items:2
            },
            1170:{
                items:3
            }
        }
    });
});